import { Tag as AntTag, TagProps as AntTagProps } from 'antd';
import { useState } from 'react';

export type { TagProps } from 'antd';

export enum TagColors {
  SUCCESS = 'success',
  PROCESSING = 'processing',
  ERROR = 'error',
  DEFAULT = 'default',
  WARNING = 'warning',
}

export const Tag = (props: AntTagProps) => {
  return <AntTag style={{ fontWeight: '500' }} {...props} />;
};

interface CheckableTagProps {
  options: string[];
}

export const CheckableTag = (props: CheckableTagProps) => {
  const { options } = props;
  const [selectedTags, setSelectedTags] = useState<string[]>(['Movies']);
  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    console.log('You are interested in: ', nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };

  return (
    <div className="flex flex-row">
      {options.map((tag) => (
        <AntTag.CheckableTag
          checked={selectedTags.includes(tag)}
          key={tag}
          onChange={(checked) => handleChange(tag, checked)}
          style={{
            height: '32px',
            lineHeight: '32px',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
          }}>
          {tag}
        </AntTag.CheckableTag>
      ))}
    </div>
  );
};
